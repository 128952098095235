$green: #009687;
$stone: #5F5F5F;
$sand: #C5B783;
$clay: #E8E5DE;
$citrus: #FFCD5A;
$sky: #C9E9E6;


.subtitle-logo {
    font-size: 1rem;
    color: $stone;
    display: flex;
    align-items: center;
}

.img-logo {
    width: 70%;
    max-width: 200px;
    padding: 10px;
}

.card-container {
    margin-top: 1rem;
    background: $clay;
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgba(233, 229, 222, 0.251);

}

.logo-whats {
    width: 10vh;
    max-width: 70px;
    margin-left: 15px;
}

.m-auto {
    width: 90vw;
    margin-right: 5vw;
    margin-left: 5vw;
}

.title-card {
    margin: 40px 10px;

    &-main-label {
        font-weight: 600;
        font-size: 40px;
        color: $green;
    }

    &-secondary-label {
        font-weight: 400;
        font-size: 25px;
        padding: 50px;
    }
}

.text-center {
    text-align: center;
}

.card-form-container {
    margin-right: 5%;
    & .title-form {
        color: $green;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-top: 1.5rem;
        text-transform: uppercase;
    }
    & .item-no-borders {
        margin-top: 1rem;
        --background: white;
        & ion-label {
            // --background: white;
            font-weight: 600;
            color: $stone;
        }
    }

    & .btn-submit-container {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        & ion-button {
            width: 50%;
            &:disabled {
                --opacity: 0.5;
            }
        }
    }
}

.interest {
    &-flex-container {
        display: flex;
        justify-content: space-around;
        margin-top: 1rem;
    }

    &-item {
        width: 20%;

        &-img {
            height: 13vh;
            max-height: 100px;

            & img {
                max-width: 100px;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }

        &-text {
            text-align: center;
            margin-top: 0.5rem;
        }

        @media screen and ( max-height: 714px ) { 
            &-text {
                margin-top: 1rem;
            }
         }
    }
}

.how-it-works-section-container {
    & .height-custom {
        height: 30vh;

    }

    & img {
        object-fit: cover;

        width: 90%;
    }

    & .label-info {
        font-size: 1.5rem;
    }
}

.title-section {
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $green;
}

.row-flex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    & .info-container {
        width: 20%;

        & .img-container {
            & img {
                width: 80;
            }
        }
    }

    & .text-container {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 600px) {
    .row-flex {
        & .info-container {
            width: 100%;
        }
    }
}

.btn-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.footer {
    width: 100vw;
    // height: 40vh;
    display: flex;
    justify-content: center;
    background-color: $sky;
    &-video {
        width: 80vw;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
}

.color-stone {
    color: $stone;
}

.flex-centered {
    display: flex;
    align-items: center;
}